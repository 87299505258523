import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/pl";

import ekstraklasaLogo from "@/images/leagues/ekstraklasa-black.png";
import friendlyMatchLogo from "@/images/leagues/friendly-match-black.png";
import polishCupLogo from "@/images/leagues/polish-cup.png";
import playerEmptyImage from "@/images/Player_empty.png";
import kumarsLogo from "@/images/logo/kumars.png";
import ChampionsLeagueLogo from "@/images/leagues/Logo_UEFA_Champions_League.png";
import EuropeLeagueLogo from "@/images/leagues/Europa_League_2021.png";
import ConferenceLeagueLogo from "@/images/leagues/uefa-europa-conference-league.png";

const OurMatchesCard = ({ data, i }) => {
  const {
    id,
    statusId,
    matchDate,
    week,
    leagueId,
    homeConName,
    awayConName,
    homeCrestImage,
    awayCrestImage,
    homeGoals,
    awayGoals,
    mvp,
    customLinkName,
    customLinkUrl,
  } = data;
  const time = moment(matchDate).format("HH:mm");

  return (
    <div
      className={`singleTM our-matches-card ${
        [2, 3].includes(statusId) && "match-live"
      }`}
    >
      <Row className="justify-content-center d-flex align-items-end m-0 pt-3 pe-0 pe-sm-3 pe-md-3 pe-lg-3 pe-xl-3">
        <Col
          lg={5}
          md={5}
          sm={5}
          className="tm_img col-3 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex justify-content-center"
        >
          <div className="our-matches-card-player-box">
            <div className="our-matches-card-sponsor-box">
              <img
                className="our-matches-card-sponsor-logo"
                src={kumarsLogo}
                alt="Logo Kumar's"
              />
              <div className="our-matches-sponsor-badge">
                <p>KUMAR'S</p>
                <p>ZAWODNIK MECZU</p>
              </div>
            </div>
            <img
              className="our-matches-card-bg"
              src={
                mvp
                  ? `${process.env.GATSBY_API_BASE_URL}/${mvp.presentationImage.path}`
                  : playerEmptyImage
              }
              alt=""
            />
          </div>
        </Col>
        <Col lg={7} md={7} sm={7} className="col-12">
          <Row className="align-items-center text-center">
            {/* <Col lg={5} className="col-5 our-matches-latest text-end">
              {i === 0 ? "OSTATNI MECZ" : ""}
            </Col> */}
            <Col
              lg={leagueId == 7 ? 12 : 7}
              className="col-12 our-matches-latest text-center"
            >
              <img
                className="our-matches-card-sponsor-logo d-inline d-sm-inline d-md-none d-lg-none d-xl-none"
                src={kumarsLogo}
                alt="Logo Kumar's"
              />
              {leagueId === 0 && (
                <img
                  className="our-matches-league-logo"
                  src={ekstraklasaLogo}
                  alt=""
                />
              )}
              {leagueId === 1 && (
                <img
                  className="our-matches-league-logo"
                  src={polishCupLogo}
                  alt=""
                />
              )}
              {leagueId === 4 && (
                <img
                  className="our-matches-league-logo"
                  src={ChampionsLeagueLogo}
                  alt=""
                />
              )}
              {leagueId === 5 && (
                <img
                  className="our-matches-league-logo"
                  src={EuropeLeagueLogo}
                  alt=""
                />
              )}
              {leagueId === 6 && (
                <img
                  className="our-matches-league-logo"
                  src={ConferenceLeagueLogo}
                  alt=""
                />
              )}
              {leagueId === 7 && (
                <img
                  className="our-matches-league-logo"
                  src={friendlyMatchLogo}
                  alt=""
                />
              )}
            </Col>
            <Col lg={leagueId == 7 ? 12 : 5} className="col-12">
              {[0, 2, 3].includes(leagueId) && (
                <span className="tickets-league-type mt-4 fs-6 fw-light">
                  {week}. kolejka
                </span>
              )}
              {leagueId === 1 && (
                <span className="tickets-league-type mt-4 fs-6 fw-light">
                  {week}
                </span>
              )}
            </Col>
          </Row>
          <Row className="align-items-center our-matches-date text-center my-4">
            <Col lg={12} className="col-12">
              {![2, 3].includes(statusId) ? (
                <>
                  <span className="our-matches-border text-center">
                    <Moment date={matchDate} format="dddd" />{" "}
                    <Moment date={matchDate} format="DD.MM.YYYY" />{" "}
                  </span>
                  <span className="ms-3 our-matches-border text-center">
                    {time}
                  </span>
                </>
              ) : (
                <span className="our-matches-border text-center our-matches-border-live">
                  Na żywo
                </span>
              )}
            </Col>
          </Row>
          <Row className="align-items-top our-matches-score justify-content-center text-center my-2">
            <Col lg={5} className="col-4 our-matches-score-names">
              <div className="rounded-circle mb-2">
                <img
                  className="our-matches-score-logos"
                  src={
                    homeCrestImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${homeCrestImage.path}`
                      : ""
                  }
                  alt=""
                />
              </div>
              {homeConName}
            </Col>
            {statusId != 1 && (
              <Col
                lg={2}
                className="col-2 our-matches-score-values p-0 m-auto d-flex justify-content-center"
              >
                {homeGoals}:{awayGoals}
              </Col>
            )}
            {statusId == 1 && (
              <Col
                lg={2}
                className="col-2 our-matches-score-values p-0 m-auto d-flex justify-content-center"
              >
                -:-
              </Col>
            )}
            <Col lg={5} className="col-4 our-matches-score-names">
              <div className="rounded-circle mb-2">
                <img
                  className="our-matches-score-logos"
                  src={
                    awayCrestImage
                      ? `${process.env.GATSBY_API_BASE_URL}/${awayCrestImage.path}`
                      : ""
                  }
                  alt=""
                />
              </div>
              {awayConName}
            </Col>
          </Row>
          <Row className="align-items-center our-matches-buttons text-center mt-5 mb-2 mb-sm-2 mb-md-2 mb-lg-5">
            <Col lg={6} md={6} sm={12} className="col-12 text-lg-end">
              {customLinkName && (
                <Link to={customLinkUrl} className="common_btn jag_dark_bg">
                  <span>{customLinkName}</span>
                </Link>
              )}
            </Col>
            <Col lg={6} md={6} sm={12} className="col-12 text-lg-start">
              <Link
                to={`/matchroom/${id}`}
                className="common_btn jag_bg matchroom-button"
              >
                <span>MATCHROOM</span>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OurMatchesCard;
