import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import "swiper/css/bundle";
import api from "@/axios";
import PanelMainBg from "@/images/profile/panel-bg.jpg";
import ekstraklasaLogo from "@/images/leagues/ekstraklasa-white.png";
import friendlyMatchLogo from "@/images/leagues/friendly-match-black.png";
import polishCupLogo from "@/images/leagues/polish-cup.png";
import ChampionsLeagueLogo from "@/images/leagues/Logo_UEFA_Champions_League.png";
import EuropeLeagueLogo from "@/images/leagues/Europa_League_2021.png";
import ConferenceLeagueLogo from "@/images/leagues/uefa-europa-conference-league.png";

const CompetitionsTable = () => {
  const [apiData, setData] = useState(null);
  const [apiDataLastMatch, setDataLastMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/stats/standings`);
        setData(response.data);

        const responseLastMatch = await api.get(`/stats/timetable`, {
          params: { mainTeam: true, venue: "home" },
        });
        let resDataLastMatch = responseLastMatch.data;
        resDataLastMatch = resDataLastMatch.filter(
          (v) => v.matchStatus === "Fixture"
        );
        setDataLastMatch(resDataLastMatch[0]);

        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
        setDataLastMatch(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    apiData?.status !== "error" && (
      <section className="commonSection">
        <Container>
          <Row>
            <Col lg={12} sm={12} className="text-start">
              <h2>Tabela PKO BP Ekstraklasy</h2>
            </Col>
          </Row>
          <Row className="gap-3 gap-sm-3 gap-md-3 gap-lg-0">
            <Col
              xl={9}
              lg={8}
              md={12}
              className="scrollable-table league-table p-0"
            >
              {loading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "550px" }}
                >
                  <span className="loader" />
                </div>
              )}
              {error && (
                <div className="text-dark">{`Wystąpił problem z wczytaniem tabeli - ${error}`}</div>
              )}
              {!loading && apiData && (
                <Table
                  borderless
                  className="competitions-table fw-bold text-center"
                >
                  <thead className="border-bottom">
                    <tr>
                      <th />
                      <th>M</th>
                      <th className="text-start w-100">NAZWA DRUŻYNY</th>
                      <th>MECZE</th>
                      <th>PKT</th>
                      <th className="competitions-column-win">W</th>
                      <th className="competitions-column-draw">R</th>
                      <th className="competitions-column-lose">P</th>
                      <th>B</th>
                      <th>FORMA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiData.map((team, index) => (
                      <tr
                        key={index}
                        className={`${
                          team.contestantId == "8jw3s68b9lil5xcgjvbcmrgc2" &&
                          "table-jaga-tr"
                        }`}
                      >
                        <td>
                          <div
                            className={`quali-signature rounded ${
                              typeof team.rankStatus !== "undefined" &&
                              team.rankStatus ==
                                "UEFA Champions League Qualifiers"
                                ? "form-win"
                                : ""
                            }${
                              typeof team.rankStatus !== "undefined" &&
                              team.rankStatus ==
                                "UEFA Conference League Qualifiers"
                                ? "form-draw"
                                : ""
                            }${
                              typeof team.rankStatus !== "undefined" &&
                              team.rankStatus == "Relegation"
                                ? "form-lose"
                                : ""
                            }`}
                          />
                        </td>
                        <td>{team.rank}.</td>
                        <td className="text-start">
                          {team.contestantClubName}
                        </td>
                        <td>{team.matchesPlayed}</td>
                        <td>{team.points}</td>
                        <td className="competitions-column-win">
                          {team.matchesWon}
                        </td>
                        <td className="competitions-column-draw">
                          {team.matchesDrawn}
                        </td>
                        <td className="competitions-column-lose">
                          {team.matchesLost}
                        </td>
                        <td>
                          {team.goalsFor}:{team.goalsAgainst}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {team.lastSix &&
                              team.lastSix
                                .split("")
                                .map((formSymbol, formIndex) => (
                                  <div
                                    className={`form-box ms-3 px-2 rounded text-white ${
                                      formSymbol == "W" && "form-win"
                                    } ${formSymbol == "D" && "form-draw"} ${
                                      formSymbol == "L" && "form-lose"
                                    }`}
                                    key={formIndex}
                                  >
                                    {formSymbol == "W" && "Z"}
                                    {formSymbol == "D" && "R"}
                                    {formSymbol == "L" && "P"}
                                  </div>
                                ))}
                            {team.lastSix &&
                              team.lastSix.length != 5 &&
                              Array(5 - team.lastSix.length)
                                .fill(null)
                                .map((el, i) => (
                                  <div
                                    key={i}
                                    className="form-box ms-3 px-2 rounded text-white form-null"
                                  >
                                    -
                                  </div>
                                ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
            {apiDataLastMatch && (
              <Col xl={3} lg={4} md={12} className="text-center ps-0">
                <div className="hero">
                  <img className="hero-profile-img" src={PanelMainBg} alt="" />
                  <div className="hero-description-bk" />
                  <div className="hero-logo">
                    <img
                      src={
                        apiDataLastMatch.homeConImage
                          ? `${process.env.GATSBY_API_BASE_URL}/${apiDataLastMatch.homeConImage.path}`
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div className="hero-logo hero-logo-away">
                    <img
                      src={
                        apiDataLastMatch.awayConImage
                          ? `${process.env.GATSBY_API_BASE_URL}/${apiDataLastMatch.awayConImage.path}`
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  {[0, 2, 3].includes(apiDataLastMatch.leagueId) && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={ekstraklasaLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">
                        {apiDataLastMatch.week}. kolejka
                      </div>
                    </>
                  )}
                  {apiDataLastMatch.leagueId === 7 && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={friendlyMatchLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">Mecz towarzyski</div>
                    </>
                  )}
                  {apiDataLastMatch.leagueId === 1 && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={polishCupLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">{apiDataLastMatch.week}</div>
                    </>
                  )}
                  {apiDataLastMatch.leagueId === 4 && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={ChampionsLeagueLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">
                        {apiDataLastMatch.week}. kolejka
                      </div>
                    </>
                  )}
                  {apiDataLastMatch.leagueId === 5 && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={EuropeLeagueLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">
                        {apiDataLastMatch.week}. kolejka
                      </div>
                    </>
                  )}
                  {apiDataLastMatch.leagueId === 6 && (
                    <>
                      <div className="hero-description">
                        <img
                          className="hero-league-logo"
                          src={ConferenceLeagueLogo}
                          alt="logo"
                        />
                      </div>
                      <div className="hero-date">
                        {apiDataLastMatch.week}. kolejka
                      </div>
                    </>
                  )}
                  <div className="hero-teams d-flex flex-column">
                    <span>{apiDataLastMatch.homeConShortName}</span>
                    <span>{apiDataLastMatch.awayConShortName}</span>
                  </div>
                  <div className="hero-btn">
                    <a href="https://bilety.jagiellonia.pl">
                      <button
                        type="button"
                        className="btn__effect profile-button profile-button-yellow me-0 me-sm-0 me-md-0 me-lg-5"
                      >
                        KUP BILET
                      </button>
                    </a>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    )
  );
};

export default CompetitionsTable;
