import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from "react-bootstrap";
import "swiper/css/bundle";

import SwiperCore, { Pagination, Autoplay } from "swiper";

SwiperCore.use([Pagination, Autoplay]);

const SponsorsCarousel = ({ homeApiData, loadingApi }) => {
  const carouselLeftOptions = {
    autoHeight: true,
    spaceBetween: 0,
    loop: true,
    slidesPerView: 1,
    centeredSlides: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: "#sponsorsleft-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  };
  const carouselRightOptions = {
    autoHeight: true,
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    centeredSlides: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: "#sponsorsright-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  };

  return loadingApi ? (
    <div className="d-flex justify-content-center align-items-center loader-sponsors-carousel">
      <span className="loader" />
    </div>
  ) : (
    <Container className="commonSection pt-0">
      <Row className="align-items-center mx-0 gap-5 gap-sm-5 gap-md-0 gap-lg-0">
        <Col lg={6} md={6} sm={12} className="sponsor-carousel-col">
          <Swiper className="sponsors-carousel-swiper" {...carouselLeftOptions}>
            {homeApiData.sponsorSliderFirstImageOne && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderFirstUrlOne}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderFirstImageOne.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
            {homeApiData.sponsorSliderFirstImageTwo && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderFirstUrlTwo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`{process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderFirstImageTwo.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
            {homeApiData.sponsorSliderFirstImageThree && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderFirstUrlThree}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderFirstImageThree.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
          </Swiper>
          <div
            className="text-center sponsors-carousel-pagination mt-1"
            id="sponsorsleft-carousel-pagination"
          />
        </Col>
        <Col lg={6} md={6} sm={12} className="sponsor-carousel-col">
          <Swiper
            className="sponsors-carousel-swiper"
            {...carouselRightOptions}
          >
            {homeApiData.sponsorSliderSecondImageOne && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderSecondUrlOne}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderSecondImageOne.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
            {homeApiData.sponsorSliderSecondImageTwo && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderSecondUrlTwo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`@{process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderSecondImageTwo.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
            {homeApiData.sponsorSliderSecondImageThree && (
              <SwiperSlide className="text-center">
                <a
                  href={homeApiData.sponsorSliderSecondUrlThree}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.GATSBY_API_BASE_URL}/${homeApiData.sponsorSliderSecondImageThree.path}`}
                    alt=""
                  />
                </a>
              </SwiperSlide>
            )}
          </Swiper>
          <div
            className="text-center sponsors-carousel-pagination mt-1"
            id="sponsorsright-carousel-pagination"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SponsorsCarousel;
